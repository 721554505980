<template>
  <div class="edu-frame">
    <edu-nav-bar title="教学计划填写" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>

    <div class="content-frame">
      <div class="edu-group class-item">
        <div class="class-detail">
          <van-row>
            <van-col span="12">申请人:{{ master.createUserType == 1 ? master.createName : master.createTeacherName }}
            </van-col>
            <van-col span="12">申请时间:{{ master.createDate }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12">审核人:{{ master.checkName }}</van-col>
            <van-col span="12">审核日期:{{ master.checkDate }}</van-col>
          </van-row>
          <van-row>
            <van-col span="24">审核备注:{{ master.checkNote }}</van-col>
          </van-row>
        </div>
      </div>
      <div v-for="(d, index) in master.details" :key="index" class="edu-group class-item">
        <div class="class-detail">
          <van-row>
            <van-col span="12">周次:{{ d.weekNO }}</van-col>
            <van-col span="12">日期:{{ d.classDateStr }}</van-col>
          </van-row>
          <van-row>
            <van-col span="24">
              <van-field :disabled="master.status==3 || (d.note != undefined && d.note != null && d.note.indexOf('放假') > -1)" style="padding: 0;" v-model="d.note" label="教学内容:"
                         placeholder="请输入教学内容"/>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div v-if="master.status!=3" class="bottom-frame">
      <van-button square block type="primary" @click="save(1)">保存不提交</van-button>
      <van-button square block type="primary" @click="save(2)">保存并提交</van-button>
    </div>

  </div>
</template>
<script>
import {Button, Row, Col, Field} from "vant";
import EduNavBar from "@/components/EduNavBar";
import Tools from "@/api/Tools";
import XEUtils from 'xe-utils';
import TeachingPlanApi from "@/api/TeachingPlanApi";

export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanRow: Row,
    VanCol: Col,
    VanField: Field,
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data() {
    return {
      show: false,
      submitting: false,
      cust: Tools.getCurCust(),
      master: {},
      teachingPlanMasterId: this.$route.query.teachingPlanMasterId,
    }
  },
  methods: {
    list() {
      var params = {teachingPlanMasterId: this.teachingPlanMasterId}
      TeachingPlanApi.get(params).then(response => {
        this.master = response.res;
      }).catch(() => {
      })
    },
    save(ss) {
      if (ss == 2) {
        for (var i = 0; i < this.master.details.length; i++) {
          if (this.master.details[i].note == null || this.master.details[i].note == '') {
            this.$dialog.alert({ message: "请填写周次为" + this.master.details[i].weekNO + "的教学内容。" });
            return
          }
        }
      }
      this.master.status = ss;
      TeachingPlanApi.upd(this.master).then(response => {
        if(response.code == 100){
          this.list();
          this.$dialog.alert({message: "操作成功"});
        }else{
          this.$dialog.alert({message: response.msg})
        }
      })
    },
  },
  mounted() {
    this.list();
  }
}
</script>
<style scoped>
</style>
